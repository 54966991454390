// Path Transit

export default {
  settings: {
    disableIframePostMessage: false,
    ctaWhiteBg: "true",
  },
  content: {
    general: {
      clientName: `Path Transit`,
      healthcheckDevLink: "https://dev.moneyfit.app/path-transit/",
    },
    heroIntro:
      "A company funded benefit provided by Gallagher, our Benefits Consultant, that encourages you to engage more with your money and help you face your financial future with confidence.",
    superFund: `
      <div class="section-title mt-5">
        <h2>
          AMP Signature Super with WesTrac
        </h2>
      </div>
      <div class="row content">
        <div class="col-lg-6">
          <p>
            By joining the company Superannuation fund, you benefit from group
            discount rates on fees and insurances. This means you pay much
            less than you would pay in a personal or individual plan.
          </p>
          <p>
            This website provides all the information you need around the
            WesTrac Superannuation Fund. There are helpful super
            tools and calculators, and information on other services we offer
            if you require additional support with your financial well-being.
          </p>
          <div class="col-lg-6 pt-4 pb-1 px-0 text-center text-lg-center">
            <a
              target="_blank"
              href="https://www.amp.com.au/amp/performance-and-unit-prices/superannuation-funds/signaturesuper"
              class="btn-learn-more"
            >
              Learn more about the AMP Super fund
            </a>
          </div>
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <p><b>Fees (per annum)</b></p>
          <ul>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Member Fee:
              <b>Nil</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> Administration Fee:
              <b>0.14%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Investment
              Fee:
              <b>0.26%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> TOTAL:
              <b>0.40%</b>
            </li>
            <li>
              <i class="ri-money-dollar-circle-line"></i> MySuper Indirect
              Cost Ratio: <b>0.28% - 0.62%</b>
            </li>
          </ul>
          <small>
            Indirect Cost Ratios are deducted from investment returns prior to
            calculation of daily unit prices. For full fee disclosure refer to
            the Fund Product Disclosure Statement (PDS)
          </small>
          <p>
            <b>Insurances (Death & Total Permanent Disability)</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Category 1 – Directors & Executives</strong>
              20% x your salary x number of years to age 65
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Category 2 – Staff</strong>
              15% x your salary x number of years to age 65
            </li>
          </ul>
          <p>
            <b>Investment Options</b>
          </p>
          <ul>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Default Investment Option:</strong>
              AMP MySuper Lifecycle
            </li>
            <li>
              <i class="ri-check-double-line"></i>
              <strong>Investment returns available at:</strong>
              <a href="https://www.amp.com.au/amp/performance-and-unit-prices/superannuation-funds/signaturesuper" target="_blank">AMP Signature Super</a>
            </li>
          </ul>
        </div>
      </div>
      `,
    modellers: [
      {
        title: "SUPERANNUATION",
        description: "Australian Super",
        type: "savings",
        url: "https://australiansuper.com/pathtransit",
        categoryPic: "savings_w.svg",
        bgImage: "savings",
      },
      {
        title: "BUDGETING",
        description: `See the potential of Money Leaks`,
        type: "budgeting",
        url: "https://lifetime.gbsau.com/gallagher-gbs/small-change/",
        categoryPic: "pie_w.svg",
        bgImage: "budgeting",
      },
      {
        title: "DEBT MANAGEMENT",
        description: "Debt Modeller",
        type: "debt",
        url: "https://lifetime.gbsau.com/gallagher/debt-modeller/",
        categoryPic: "debt_w.svg",
        bgImage: "debt",
      },
      // {
      //   title: "SUPERANNUATION",
      //   description: "Lifetime Supermodeller",
      //   type: "budgeting",
      //   url: "https://www.australiansuper.com/",
      //   categoryPic: "pie_w.svg",
      //   bgImage: "supermodeller",
      // },
      {
        title: "SUPERANNUATION",
        description: "Understanding super",
        type: "savings",
        url: "https://lifetime.gbsau.com/gallagher/supermodeller/",
        categoryPic: "savings_w.svg",
        bgImage: "understanding_super",
      },
      // {
      //   title: "INSURANCE",
      //   description: "Insurance Needs",
      //   type: "budgeting",
      //   url: "https://www.australiansuper.com/",
      //   categoryPic: "pie_w.svg",
      //   bgImage: "insurance",
      // },
      {
        heading: "MoneyFit Healthcheck",
        subheading: "Financial Wellbeing",
        description:
          "Uncover your financial tendencies. Explore your unique approach to money management and financial decisionmaking. MoneyFit can help you achieve financial wellbeing. It aims to empower you to feel confident and in control of your finances.",
        link: "LAUNCH MONEYFIT HEALTH CHECK",
        url: "/healthcheck/",
        categoryPic: "owl.png",
        moneyFit: true,
        type: "budgeting",
      },
    ],
    optIn: "AMP_Insurance_OPT-IN_Form.pdf",
    teamMembers: [
      {
        teamMemberPic: "phil.png",
        name: "Phil Mills",
        role: "Senior Consultant",
        phoneHref: "0862508399",
        phone: "08 6250 8399",
        email: "phil_mills@ajg.com.au",
        social: "https://au.linkedin.com/pub/philip-mills/67/701/268",
      },
      {
        teamMemberPic: "silvia.png",
        name: "Silvia Pothoven",
        role: "Executive Manager",
        phoneHref: "0292422117",
        phone: "02 9242 2117",
        email: "silvia_pothoven@ajg.com.au",
        social: "https://www.linkedin.com/in/silviainfante",
      },
      {
        teamMemberPic: "anthony.png",
        name: "Anthony Warman",
        role: "Executive Manager",
        phoneHref: "0862508308",
        phone: "08 6250 8308",
        email: "anthony_warman@ajg.com.au",
        social: "http://au.linkedin.com/pub/anthony-warman/7b/53a/75a",
      },
      {
        teamMemberPic: "dianna.png",
        name: "Dianna Gilbert",
        role: "Team Operations Manager",
        phoneHref: "1300557782",
        phone: "1300 557 782",
        email: "dianna_gilbert@ajg.com.au",
      },
    ],
    footerClientName: "WesTrac",
  },

  superscore: true,
  team: ["phil", "dianna", "silvia", "anthony"],

  analytics: {
    googleTrackingId: "G-5YD24PJES5",
    enableClarity: true,
    clarityTrackingId: "lc6i3pgkl8",
  },
};
